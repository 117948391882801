import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import logoMobile from "../img/logo_white.png";
import Fade from 'react-reveal/Fade';
import Layout from "../components/Layout";
import FieldsOfStudy from "../components/FieldsOfStudy";
import Metrics from "../components/Metrics";
import Programmes from "../components/Programmes";
import Quotes from "../components/Quotes";
import Timeline from "../components/Timeline";
import Pulse from 'react-reveal/Pulse';
import FullWidthImage from "../components/FullWidthImage";
import ReactPlayer from 'react-player'; // If using videos
import VideoPlayer from "../components/VideoPlayer";
import AnimateIn from "../components/AniamteIn";
import InfoPanel from "../components/InfoPanel/InfoPanel";


// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  media,
  timeline,
  quotes,
  infoPanel,
  registerPanel,
  scholarshipPanel,
  programmes,
  mainpitch,
  fieldsOfStudy,
  metrics,
  banner_image_1,
  description,
  intro,
}) => {
  const ourRef = useRef(null)
  console.log(timeline, "timeline")
  const [isIntersecting, setIsIntersecting] = useState(false);
  const scrollCallback = (entries) => {

    if (entries[0].isIntersecting) {
      setIsIntersecting(true)
    } else {
      setIsIntersecting(false)
    }
  };


  useEffect(() => {
    const observer = new IntersectionObserver(scrollCallback, {
      root: null,
      rootMargin: '800px 900px -30%',
      threshold: 0.2, // Trigger when 50% of the element is in view
    });
    observer.observe(ourRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <React.Fragment>

      <section className="hero">
        <div className="image-container">
          <Fade bottom>
            <section className="hero-content">
              <div className="logo">
                <img src={logoMobile} alt="George Moore" />
              </div>
              <h1 className="fs-primary-heading">{title}</h1>
              <p className="banner-paragraph">{description}</p>
            </section>
          </Fade>
          <div className="image-wrapper">
            <div class="clip-container">
              <div class="rounded-inner">
                <FullWidthImage img={media} title={title} subheading={subheading} translateY={'-6rem'}>
                  <span className="hero-buttons">
                    <a target="_blank" href="https://drive.google.com/file/d/1nOTJz5ftbgg7aJg3Lvi1NlR6l4qEOVF7/view?usp=sharing"><button class="button secondary">Application Guide</button></a>
                    <a target="_blank" href="https://www.mindaclient.com/clientportal/index.php?g=21dc0c3c-d482-11e9-9f25-0cc47a828785"><button class="apply-btn button">Applications Now Closed</button></a>
                  </span>
                </FullWidthImage>
              </div>
              <svg class="flt_svg" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <filter id="flt_tag">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                    <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>

        </div>

      </section>

      <VideoPlayer metrics={metrics} />
      <Timeline timelineSteps={timeline} registerPanel={registerPanel} scholarshipPanel={scholarshipPanel} />
      {/* <Metrics metrics={metrics} /> */}

      <div ref={ourRef} className={`mid-banner-image`}>
        <div className={`mid-banner-wrapper ${isIntersecting ? 'animateZoom' : ''}`}>
          <FullWidthImage img={getImage(banner_image_1.image) || banner_image_1.image} title={banner_image_1.title}  >
          </FullWidthImage>
        </div>
      </div>
      <Quotes quotes={quotes} />
      <InfoPanel infoPanel={infoPanel} />

    </React.Fragment >
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  console.log(frontmatter, "frontmatter")


  return (
    <Layout isRootPage={true}>
      <IndexPageTemplate
        media={getImage(frontmatter.media)}
        banner_image_1={frontmatter.banner_image_1}
        scholarshipPanel={frontmatter.scholarship_panel}
        timeline={frontmatter.timeline.step_1}
        image={frontmatter.image}
        title={frontmatter.title}
        registerPanel={frontmatter.register_panel}
        programmes={frontmatter.programmes}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        infoPanel={frontmatter.info_panel}
        description={frontmatter.description}
        fieldsOfStudy={frontmatter.fields_of_study}
        metrics={frontmatter.metrics}
        intro={frontmatter.intro}
        quotes={frontmatter.quotes}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      
      frontmatter {
        title

        timeline {
          step_1 {
            title
            subtitle
          }
      }
        banner_image_1  {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        title
      }

      scholarship_panel {
        description
        title
     }

      register_panel {
          description
          title
      }

      info_panel {
          title
          description
          image {
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
          about
        }
        media {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        quotes {
              title
              description
              quotes {
                name
                image {
                childImageSharp {
                  gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
                quoteText
                degree
                university
              }
            } 
        programmes {
            title
            programme {
              title
              category
            }
            description
            categories {
              name
            }
           
          }
        metrics {
						number
            title
            description
            icon
          }
        fields_of_study {
 						title
            description
            field_study_items {
              heading
              image {
              childImageSharp {
                gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
              }
             }
            }
          }
        image {
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
      
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, placeholder: BLURRED, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
